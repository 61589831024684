























import {
  defineComponent,
  useContext
} from '@nuxtjs/composition-api';
import {
  useUiState,
} from '~/composables';

import Icon from "@/almarwan/components/Icon.vue";

export default defineComponent({
  name: "NewsLetter",
  components: {
    Icon,
  },
  setup() {
    const { toggleMailingListModal } = useUiState();

    const toggleAuthModal = async () => {
      toggleMailingListModal();
    };
    const { app: { i18n } } = useContext();
    const placeholderTxt = i18n.t('Enter your email')

    return {
      toggleAuthModal,
      placeholderTxt
    }
  }
});
